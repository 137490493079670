import { useLazyQuery, useMutation } from '@apollo/client';
import { DefaultButton, Spinner, TooltipHost } from '@fluentui/react';
import { ExportDocumentsStatusType } from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { DocumentEntity } from '../list';
import { SuccessMessage } from './SuccessMessage';
import {
  ExportDocuments,
  ExportDocumentsVariables,
} from './__generated__/ExportDocuments';
// import {
//   ExportDocumentsStatusQuery,
//   ExportDocumentsStatusQueryVariables,
// } from './__generated__/ExportDocumentsStatusQuery';
import { doArraysHaveDifferentValues } from 'common/utils';

const EXPORT_DOCUMENTS = loader('./ExportDocuments.graphql');
// const EXPORT_DOCUMENTS_STATUS_QUERY = loader(
//   './ExportDocumentsStatusQuery.graphql'
// );

interface MultipleDownloadProps {
  selectedList: Pick<DocumentEntity, 'id'>[];
}

export const MultipleDownload: React.FC<MultipleDownloadProps> = ({
  selectedList,
}) => {
  const { addToast, updateToast } = useToasts();
  const [documentIds, setDocumentIds] = useState<string[]>([]);
  const [isPolling, setPolling] = useState(false);
  const toastId = `multiDocuments`;

  const [generateJobId, { loading: loadingJobId }] = useMutation<
    ExportDocuments,
    ExportDocumentsVariables
  >(EXPORT_DOCUMENTS, { errorPolicy: 'all' });

  // const [fetchDocuments, { stopPolling }] = useLazyQuery<
  //   ExportDocumentsStatusQuery,
  //   ExportDocumentsStatusQueryVariables
  // >(EXPORT_DOCUMENTS_STATUS_QUERY, {
  //   pollInterval: 1000,
  //   notifyOnNetworkStatusChange: true,
  //   fetchPolicy: 'network-only',
  //   nextFetchPolicy: 'cache-only',
  //   onCompleted(data) {
  //     if (data.exportDocumentsStatus) {
  //       const { status, downloadLink } = {
  //         ...data.exportDocumentsStatus,
  //       };
  //       if (status === ExportDocumentsStatusType.SUCCESS) {
  //         updateToast(toastId!, {
  //           content: <SuccessMessage downloadLink={downloadLink} />,
  //           appearance: 'success',
  //           autoDismiss: true,
  //         });

  //         stopPolling?.();
  //         setPolling(false);
  //       } else if (status === ExportDocumentsStatusType.FAILURE) {
  //         updateToast(toastId!, {
  //           content: 'Failed to download the documents.',
  //           appearance: 'error',
  //           autoDismiss: true,
  //         });

  //         stopPolling?.();
  //         setPolling(false);
  //       }
  //     }
  //   },
  // });

  const onClick = async () => {
    // const { errors, data } = await generateJobId({
    //   variables: {
    //     input: {
    //       documentIds,
    //     },
    //   },
    // });
    // if (!!errors?.length)
    //   addToast(errors?.[0].message, { appearance: 'error' });
    // else {
    //   if (!!data?.exportDocuments.exportDocumentId) {
    //     addToast(`Exporting ${documentIds.length} files...`, {
    //       appearance: 'info',
    //       id: toastId,
    //       autoDismiss: true,
    //     });
    //     // const jobId = data?.exportDocuments.jobId;
    //     // setPolling(true);
    //     // fetchDocuments({ variables: { jobId } });
    //   }
    // }
  };

  useEffect(() => {
    const newDocumentIds = selectedList.map((ele) => ele.id);
    const result = doArraysHaveDifferentValues(documentIds, newDocumentIds);
    if (result) setPolling(false);
    setDocumentIds(newDocumentIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedList]);

  return (
    <>
      <TooltipHost content="Download">
        {loadingJobId || isPolling ? (
          <Spinner />
        ) : (
          <DefaultButton
            iconProps={{ iconName: 'Download' }}
            onClick={onClick}
            text="Export"
          />
        )}
      </TooltipHost>
    </>
  );
};
