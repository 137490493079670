import {
  Callout,
  DirectionalHint,
  IconButton,
  PrimaryButton,
  Stack,
  Text,
  TextField,
} from '@fluentui/react';
import React, { useState } from 'react';
import { useId } from '@fluentui/react-hooks';
import { StandardCalculationType } from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/client';
import {
  DateCalcTermDays,
  DateCalcTermDaysVariables,
} from 'common/graphql/__generated__/DateCalcTermDays';
import {
  dateCalcTermWeeks,
  dateCalcTermWeeksVariables,
} from 'common/graphql/__generated__/dateCalcTermWeeks';
import {
  DateCalcTermMonths,
  DateCalcTermMonthsVariables,
} from 'common/graphql/__generated__/DateCalcTermMonths';
import NumberFormat from 'react-number-format';
import { useCommonStyles } from 'common/styles';
import { PurchaseOrderCommonData_companyPurchaseOrderTypes_nodes_companyPurchaseOrderItemTypes_nodes_unitOfMeasureGroup_unitOfMeasureItemsByUnitOfMeasureGroupId_nodes } from 'purchaseOrder/view/__generated__/PurchaseOrderCommonData';
import {
  DateCalcTermNights,
  DateCalcTermNightsVariables,
} from 'common/graphql/__generated__/DateCalcTermNights';

const DATE_CALC_DAYS = loader(
  '../../../../../../../common/graphql/DateCalcTermDays.graphql'
);
const DATE_CALC_MONTHS = loader(
  '../../../../../../../common/graphql/DateCalcTermMonths.graphql'
);
const DATE_CALC_WEEKS = loader(
  '../../../../../../../common/graphql/DateCalcTermWeeks.graphql'
);
const DATE_CALC_NIGHTS = loader(
  '../../../../../../../common/graphql/DateCalcTermNights.graphql'
);

export type RateFrequencyProp =
  PurchaseOrderCommonData_companyPurchaseOrderTypes_nodes_companyPurchaseOrderItemTypes_nodes_unitOfMeasureGroup_unitOfMeasureItemsByUnitOfMeasureGroupId_nodes;

interface EndDateCalculatorProps {
  startDate: string;
  calculationType: StandardCalculationType | null | undefined;
  abbreviation?: string | null | undefined;
  onEndDateReturn: (endDate: string, term?: string | undefined) => void;
  disabled: boolean;
  label?: string;
}
export const EndDateCalculator: React.FC<EndDateCalculatorProps> = ({
  startDate,
  calculationType,
  onEndDateReturn,
  abbreviation,
  disabled,
  label = 'Calculate end date',
}) => {
  const commonStyles = useCommonStyles();
  const [visible, setVisible] = useState(false);
  const buttonId = useId('OverrideApprovalHint');
  const [term, setTerm] = useState<string | undefined>();
  const [getDaysEndDate] = useLazyQuery<
    DateCalcTermDays,
    DateCalcTermDaysVariables
  >(DATE_CALC_DAYS, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
      if (data.dateCalcTermDays) onEndDateReturn(data.dateCalcTermDays, term);
    },
  });

  const [getWeeksEndDate] = useLazyQuery<
    dateCalcTermWeeks,
    dateCalcTermWeeksVariables
  >(DATE_CALC_WEEKS, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
      if (data.dateCalcTermWeeks) onEndDateReturn(data.dateCalcTermWeeks, term);
    },
  });

  const [getMonthsEndDate] = useLazyQuery<
    DateCalcTermMonths,
    DateCalcTermMonthsVariables
  >(DATE_CALC_MONTHS, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
      if (data.dateCalcTermMonths)
        onEndDateReturn(data.dateCalcTermMonths, term);
    },
  });

  const [getDaysNightEndDate] = useLazyQuery<
    DateCalcTermNights,
    DateCalcTermNightsVariables
  >(DATE_CALC_NIGHTS, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
      if (data.dateCalcTermNights)
        onEndDateReturn(data.dateCalcTermNights, term);
    },
  });

  const onCalculate = async () => {
    const termInputNumber = parseInt(term || '0');
    if (termInputNumber) {
      switch (calculationType) {
        case StandardCalculationType.PO_DAILY_NIGHTLY:
          getDaysNightEndDate({
            variables: { startDate, days: termInputNumber },
          });
          break;
        case StandardCalculationType.PO_DAILY:
        case StandardCalculationType.PO_MULTI_DAY:
          getDaysEndDate({
            variables: { startDate, days: termInputNumber },
          });
          break;
        case StandardCalculationType.PO_WEEK_7_DAY:
        case StandardCalculationType.PO_3_DAY_WEEK_7_DAY:
        case StandardCalculationType.PO_WEEK_5_DAY:
        case StandardCalculationType.PO_BI_WEEK_7_DAY:
        case StandardCalculationType.PO_BI_WEEK_5_DAY:
          getWeeksEndDate({ variables: { startDate, weeks: termInputNumber } });
          break;
        default:
          getMonthsEndDate({
            variables: { startDate, months: termInputNumber },
          });
          break;
      }
      setVisible(false);
    }
  };

  if (calculationType && startDate)
    return (
      <Stack>
        <IconButton
          iconProps={{ iconName: 'CalendarSettings' }}
          ariaLabel="Collapse"
          style={{ marginTop: 26 }}
          id={buttonId}
          disabled={disabled}
          onClick={() => setVisible(true)}
        />
        {visible && (
          <Callout
            gapSpace={0}
            target={`#${buttonId}`}
            directionalHint={DirectionalHint.topCenter}
            onDismiss={() => setVisible(false)}
          >
            <Stack
              tokens={{
                padding: '10px 20px',
                childrenGap: 10,
              }}
            >
              {/* <TextField label="Enter Days" placeholder="Term" onChange={(_, value)=> setTerm(value)}/> */}
              <Text className={commonStyles.bold} variant="mediumPlus">
                {label}
              </Text>
              <NumberFormat
                style={{ width: 200 }}
                value={term || ''}
                customInput={TextField}
                placeholder={abbreviation || ''}
                onValueChange={(valuesObject, info) => {
                  if (info.source === 'event') setTerm(valuesObject?.value);
                }}
              />

              <Stack horizontal verticalAlign="center">
                <PrimaryButton
                  text="Calculate"
                  onClick={onCalculate}
                  styles={{ root: { width: 100 } }}
                />
              </Stack>
            </Stack>
          </Callout>
        )}
      </Stack>
    );
  else return null;
};
