import { useQuery, useReactiveVar } from '@apollo/client';
import { Stack } from '@fluentui/react';
import { UserDefaults } from 'Preferences/__generated__/UserDefaults';
import { PayCycle } from 'ap/paymentCycle';
import { AccountingEntryPayCycleView } from 'ap/paymentCycle/AccountingEntry/view';
import { PayCycleDetails } from 'ap/paymentCycle/view';
import { PayTrackingSection } from 'ap/paymentTracking';
import { Signing } from 'ap/signing';
import { AccountingEntryView } from 'ap/signing/accountingEntry/view';
import { TransactionSigningView } from 'ap/signing/transactionSigning/view';
import {
  ApprovalDetails,
  ApprovalHistoryDetails,
} from 'approvals/ApprovalDetails';
import { ApprovalsAllUsers } from 'approvals/ApprovalsAllUsers';
import { Approvals } from 'approvals/MyApprovals';
import clsx from 'clsx';
import { Loader } from 'common/components/Loader';
import { CurrentUserProfile } from 'common/graphql/__generated__/CurrentUserProfile';
import { UserAppPrivileges } from 'common/graphql/__generated__/UserAppPrivileges';
import { useCommonStyles } from 'common/styles';
import { AppRouter } from 'common/types/globalTypes';
import { getRoutePath } from 'common/utils/defaultRouter';
import { Dashboard } from 'dashboard';
import { DocumentAssignmentSection } from 'documents/documentAssignment';
import { DocumentAssignmentView } from 'documents/documentAssignment/folder/view';
import { DocumentPoolView } from 'documents/documentsPool/Views/DocumentPoolView';
import { DocumentsPool } from 'documents/documentsPool/list';
import { FileCabinetSection } from 'documents/fileCabinet';
import { FileCabinetDocumentView } from 'documents/fileCabinet/folder/view';
import { PersonalPool } from 'documents/personalPool';
import { NotFound } from 'error/NotFound';
import { loader } from 'graphql.macro';
import { NavBar } from 'nav';
import { BatchEditSection } from 'postingTracker/batchEdit';
import { BatchDetails } from 'postingTracker/batchEdit/batch/view';
import { TransactionHistorySection } from 'postingTracker/transactionHistory';
import { TransactionHistoryBatchDetails } from 'postingTracker/transactionHistory/batch/view';
import { PurchaseOrderList } from 'purchaseOrder/list';
import { PurchaseOrderView } from 'purchaseOrder/view';
import React, { useCallback, useEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { AccountManagement } from 'settings/account';
import { TravelersView } from 'settings/account/Travelers/view';
import { ApprovalSetupView } from 'settings/account/approvalSetup/view';
import { CorporateWorkGroupView } from 'settings/account/corporateWorkGroups/view';
import { SupplierDetails } from 'settings/account/supplier/view';
import { UserSetupView } from 'settings/account/userSetup/view';
import { ProjectSettings } from 'settings/project';
import { CardAccountView } from 'settings/project/purchaseCards/view/CardAccountView';
import { CardHolderView } from 'settings/project/purchaseCards/view/CardHolderView';
import { TopBar } from 'topBar';
import { TravelAuthorization } from 'travelAuthorization/TravelPlan/list';
import { TravelAuthorizationView } from 'travelAuthorization/TravelPlan/view';
import { TripBreakdownList } from 'travelAuthorization/TripBreakdown/list';
import { currentUserRole } from 'utility/cache';
import {
  setCurrentProfile,
  setUserDefaults,
  setUserPrivileges,
} from 'utility/cache/ui';
import { PaymentTrackingView } from '../ap/paymentTracking/view/PaymentTrackingView';
import { useStyles } from './index.styles';
import { UserTemplateViewModal } from 'settings/account/userTemplates/view';
import { CommonDataOnAppLoading } from 'common/graphql/__generated__/CommonDataOnAppLoading';

const USER_DEFAULTS = loader('../Preferences/UserDefaults.graphql');
const PRIVILEGES = loader('../common/graphql/UserAppPrivileges.graphql');
const CURRENT_USER_PROFILE = loader(
  '../common/graphql/CurrentUserProfile.graphql'
);
const COMMON_APP_DATA = loader(
  '../common/graphql/CommonDataOnAppLoading.graphql'
);

export const App: React.FC = () => {
  const history = useHistory();
  const styles = useStyles();

  const commonStyles = useCommonStyles();
  const currentProfile = useReactiveVar(setCurrentProfile);
  const role = useReactiveVar(currentUserRole);
  const { data, loading } = useQuery<UserAppPrivileges>(PRIVILEGES);
  const { data: userDefaults } = useQuery<UserDefaults>(USER_DEFAULTS, {});

  const { data: currentUserData } =
    useQuery<CurrentUserProfile>(CURRENT_USER_PROFILE);
  const UserPrivileges = useReactiveVar(setUserPrivileges);

  useEffect(() => {
    document.title = 'CUT! Expenses';
  }, []);

  useEffect(() => {
    setUserDefaults(userDefaults?.userDefaults?.nodes[0]);
  }, [userDefaults]);

  const { data: commonData } =
    useQuery<CommonDataOnAppLoading>(COMMON_APP_DATA);
  const setRoutePrivileges = useCallback(() => {
    const newArr: string[] =
      data?.userAppPrivileges?.nodes.map((item) => item.linkType!) || [];
    const privilegesSet = new Set<string>([...newArr]);
    setUserPrivileges(privilegesSet);
    setCurrentProfile(currentUserData);

    const defaultPath = getRoutePath(
      currentUserData?.currentUserProfile?.defaultAppRouter
    );
    if (defaultPath !== null && history.location.pathname === '/') {
      history.push(defaultPath);
    }
  }, [data, currentUserData, history]);

  useEffect(() => {
    setRoutePrivileges();
  }, [setRoutePrivileges]);

  return (
    <Stack className={clsx(styles.root, commonStyles.noScroll)} grow>
      <TopBar />
      <Stack horizontal grow className={commonStyles.noScroll} disableShrink>
        <NavBar />
        {!loading && UserPrivileges.size !== 0 && !!currentProfile ? (
          <Stack grow className={styles.content}>
            <Switch>
              <Route path="/" exact component={Dashboard} />

              {UserPrivileges.has(AppRouter.TRANSACTION_SIGNING) && (
                <Route path="/signing">
                  <Signing />
                </Route>
              )}

              {UserPrivileges.has(AppRouter.PAYMENT_TRACKING) && (
                <Route path="/pay/payment_tracking">
                  <PayTrackingSection />
                </Route>
              )}
              {UserPrivileges.has(AppRouter.PAY_CYCLE) && (
                <Route path="/pay/payment_cycle">
                  <PayCycle />
                </Route>
              )}

              {UserPrivileges.has(AppRouter.BATCH_EDIT) && (
                <Route path="/postingTracker/batches">
                  <BatchEditSection />
                </Route>
              )}
              {UserPrivileges.has(AppRouter.POSTED_TRANSACTIONS) && (
                <Route path="/postingTracker/history">
                  <TransactionHistorySection />
                </Route>
              )}

              {UserPrivileges.has(AppRouter.APPROVALS) && (
                <Route path="/approvals">
                  <Approvals />
                </Route>
              )}
              {(!!role?.isProjectSupervisor ||
                UserPrivileges.has(AppRouter.APPROVAL_HISTORY)) && (
                <Route path="/approval_history">
                  <ApprovalsAllUsers />
                </Route>
              )}
              {UserPrivileges.has(AppRouter.PRIVATE_POOL) && (
                <Route path="/doc/personal">
                  <PersonalPool />
                </Route>
              )}
              <Route path="/doc/groups">
                <DocumentsPool />
              </Route>
              <Route path="/doc/documentAssignment">
                <DocumentAssignmentSection />
              </Route>
              {UserPrivileges.has(AppRouter.FILE_CABINET) && (
                <Route path="/doc/fileCabinet">
                  <FileCabinetSection />
                </Route>
              )}
              {!!role?.isProjectSupervisor && (
                <Route path="/project-settings">
                  <ProjectSettings />
                </Route>
              )}
              {!!role?.isAdministrator && (
                <Route path="/account-management">
                  <AccountManagement />
                </Route>
              )}
              {UserPrivileges.has(AppRouter.PURCHASE_ORDER) && (
                <Route path="/purchase-orders">
                  <PurchaseOrderList />
                </Route>
              )}
              {UserPrivileges.has(AppRouter.TRAVEL_AUTHORIZATION) && (
                <Route path="/ta/travel-plan">
                  <TravelAuthorization />
                </Route>
              )}
              {UserPrivileges.has(AppRouter.TRAVEL_AUTHORIZATION) && (
                <Route path="/ta/trip-breakdown">
                  <TripBreakdownList />
                </Route>
              )}
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
            <Switch>
              <Route path={'/approvals/approval/:approvalId'}>
                <ApprovalDetails />
              </Route>
              {(!!role?.isAdministrator ||
                UserPrivileges.has(AppRouter.USER_SETUP)) && (
                <Route
                  path={[
                    '/account-management/users/user/:userId',
                    '/account-management/users/user',
                  ]}
                >
                  <UserSetupView />
                </Route>
              )}
              {(!!role?.isAdministrator ||
                UserPrivileges.has(AppRouter.DEPARTMENT_OCC_TEMPLATE)) && (
                <Route
                  path={[
                    '/account-management/userTemplates/userTemplate/:userId',
                    '/account-management/userTemplates/userTemplate',
                  ]}
                >
                  <UserTemplateViewModal />
                </Route>
              )}

              {(!!role?.isAdministrator ||
                UserPrivileges.has(AppRouter.SUPPLIER)) && (
                <Route
                  path={[
                    '/account-management/suppliers/supplier/:supplierId',
                    '/account-management/suppliers/supplier',
                  ]}
                >
                  <SupplierDetails />
                </Route>
              )}

              {UserPrivileges.has(AppRouter.PCARD_ACCOUNT) && (
                <Route
                  path={[
                    '/project-settings/purchase-cards/card-account/:cardAccountId',
                  ]}
                >
                  <CardAccountView />
                </Route>
              )}
              {UserPrivileges.has(AppRouter.PCARD_ACCOUNT) && (
                <Route
                  path={[
                    '/project-settings/purchase-cards/card-holder/:cardHolderId',
                    '/project-settings/purchase-cards/card-holder',
                  ]}
                >
                  <CardHolderView />
                </Route>
              )}
              {(!!role?.isAdministrator ||
                UserPrivileges.has(AppRouter.APPROVAL_SETUP)) && (
                <Route
                  path={[
                    '/account-management/approvals/approval/:approvalId',
                    '/account-management/approvals/approval',
                  ]}
                >
                  <ApprovalSetupView />
                </Route>
              )}
              {(!!role?.isProjectSupervisor ||
                UserPrivileges.has(AppRouter.APPROVAL_HISTORY)) && (
                <Route path={'/approval_history/approval/:approvalHistoryId'}>
                  <ApprovalHistoryDetails />
                </Route>
              )}

              <Route
                path={[
                  '/doc/documentAssignment/document/:documentId/:selectedPoolId',
                  '/doc/documentAssignment/document/:documentId',
                ]}
              >
                <DocumentAssignmentView />
              </Route>

              {UserPrivileges.has(AppRouter.TRANSACTION_SIGNING) && (
                <Route path={'/signing/accounting/:accountingEntryId'}>
                  <AccountingEntryView />
                </Route>
              )}

              {UserPrivileges.has(AppRouter.TRANSACTION_SIGNING) && (
                <Route
                  path={[
                    '/signing/transaction/:transactionSigningId',
                    '/signing/transaction',
                  ]}
                >
                  <TransactionSigningView />
                </Route>
              )}
              <Route path={'/pay/payment_cycle/accounting/:accountingEntryId'}>
                <AccountingEntryPayCycleView />
              </Route>

              {UserPrivileges.has(AppRouter.BATCH_EDIT) && (
                <Route
                  path={[
                    '/postingTracker/batches/batch/:batchId',
                    '/postingTracker/batches/batch',
                  ]}
                >
                  <BatchDetails />
                </Route>
              )}

              {UserPrivileges.has(AppRouter.POSTED_TRANSACTIONS) && (
                <Route
                  path={[
                    '/postingTracker/history/batch/:batchId',
                    '/postingTracker/history/batch',
                  ]}
                >
                  <TransactionHistoryBatchDetails />
                </Route>
              )}

              {UserPrivileges.has(AppRouter.PAY_CYCLE) && (
                <Route
                  path={[
                    '/pay/payment_cycle/cycle/:cycleId',
                    '/pay/payment_cycle/cycle',
                  ]}
                >
                  <PayCycleDetails />
                </Route>
              )}
              {UserPrivileges.has(AppRouter.PAYMENT_TRACKING) && (
                <Route
                  path={[
                    '/pay/payment_tracking/payment/:paymentId',
                    '/pay/payment_tracking/payment',
                  ]}
                >
                  <PaymentTrackingView />
                </Route>
              )}

              {UserPrivileges.has(AppRouter.DOCUMENT_POOL) && (
                <Route
                  path={[
                    '/doc/groups/group/:documentGroupId',
                    '/doc/groups/group',
                  ]}
                >
                  <DocumentPoolView />
                </Route>
              )}
              {UserPrivileges.has(AppRouter.TRAVELER) && (
                <Route
                  path={[
                    '/account-management/travelers/traveler/:travelerId',
                    '/account-management/travelers/traveler',
                  ]}
                >
                  <TravelersView />
                </Route>
              )}
              {UserPrivileges.has(AppRouter.FILE_CABINET) && (
                <Route
                  path={[
                    '/doc/fileCabinet/document/:documentId/:selectedPoolId',
                    '/doc/fileCabinet/document/:documentId',
                  ]}
                >
                  <FileCabinetDocumentView />
                </Route>
              )}
              {UserPrivileges.has(AppRouter.PURCHASE_ORDER) && (
                <Route
                  path={[
                    '/purchase-orders/purchase-order/:purchaseOrderId',
                    '/purchase-orders/purchase-order',
                  ]}
                >
                  <PurchaseOrderView />
                </Route>
              )}

              {UserPrivileges.has(AppRouter.TRAVEL_AUTHORIZATION) && (
                <Route
                  path={[
                    '/ta/travel-plan/travel/:travelId',
                    '/ta/travel-plan/travel',
                  ]}
                >
                  <TravelAuthorizationView />
                </Route>
              )}
              {UserPrivileges.has(AppRouter.CORPORATE_WORKGROUPS) && (
                <Route
                  path={[
                    '/account-management/work-groups/work-group/:workGroupId',
                    '/account-management/work-groups/work-group',
                  ]}
                >
                  <CorporateWorkGroupView />
                </Route>
              )}
              {/* {UserPrivileges.has(AppRouter.TRAVELER) && (
                <Route path="/account-management/departments-list">
                  <DepartmentsList />
                </Route>
              )} */}
            </Switch>
          </Stack>
        ) : (
          <Stack grow className={styles.content}>
            <Loader />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
